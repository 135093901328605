import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";
import verifyToken from "@services/Auth/verifyToken";
import { deleteAllCookies } from "@utils/cookies";
import { IPrivateRoutesProps } from "@routes/interface";
import {
  addToLocalStorage,
  clearAllLocalStorage,
  getValueFromLocalStorage,
} from "@utils/local-storage";

export default function PrivateRoutes({
  children,
}: IPrivateRoutesProps): JSX.Element {
  const [isVerified, setIsVerified] = useState(
    !!getValueFromLocalStorage("token")
  );
  const { handleFetchVerifyToken } = verifyToken();

  useEffect(() => {
    handleFetchVerifyToken()
      .then((response) => {
        if (!getValueFromLocalStorage("user")) {
          const user = response?.data?.user;
          addToLocalStorage(
            "userPermissions",
            JSON.stringify(user?.permissions)
          );
          addToLocalStorage("user", JSON.stringify(user));
        }
      })
      .catch((authError: any) => {
        if (!axios.isCancel(authError)) {
          setIsVerified(false);
          deleteAllCookies();
          clearAllLocalStorage();
        }
      });
  }, [children]);

  // * fetch token from localstorage
  // const TOKEN = getValueFromCookie('token');

  /**
   * ? check if token and user data exist then render the component
   * ? else redirect to login
   */
  return isVerified ? (
    children
  ) : (
    <Navigate
      state={{
        from: window.location.pathname,
      }}
      to={{ pathname: "/login" }}
    />
  );
}
