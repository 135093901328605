import { FLAGTYPES } from "@pages/Application/Tickets/components/loanDetails/utils/constants";
import { LoanDetailsType } from "@pages/Application/Tickets/components/loanDetails/utils/types";
import useGetSignedUrlTakeover from "@services/GetSignedUrl/getSignedUrlsTakeOver";
import React, { createContext, useMemo, useState } from "react";

const LoanContext = createContext<any>({});

function LoanProvider({ children }: any) {
  const [fetchBrlLoanDetailsLoading, setFetchBrlLoanDetailsLoading] =
    useState(false);
  const [defaultReasons, setDefaultReasons] = useState<string[]>([]);
  const [toggleFlagModal, setToggleFlagModal] = useState<boolean>(false);
  const [toggleApproveLoanModal, setToggleApproveLoanModal] =
    useState<boolean>(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [excessbuttonStatus, setExcessButtonStatus] = useState("");
  const [toggleChangeBankAccountModal, setToggleChangeBankAccountModal] =
    useState<boolean>(false);
  const [toggleViewBankProofImageModal, setToggleViewBankProofImageModal] =
    useState({
      toggle: false,
      imageUrl: "",
      fileName: "",
    });
  const [toggleExcessAmountTransferModal, setToggleExcessAmountTransferModal] =
    useState<{
      toggle: boolean;
      transactionItem: any;
      number: number;
    }>({
      toggle: false,
      transactionItem: {},
      number: 0,
    });
  const [toggleRecordFundTransferGLModal, setToggleRecordFundTransferGLModal] =
    useState<any>({
      toggle: false,
      amount: null,
    });
  const [excessFundTransferDataByRazorPay, setExcessTransferDataByRazorPay] =
    useState<any>();
  const [excessFundTransferDataByManual, setExcessTransferDataByManual] =
    useState<any>();

  const { handleGetSignedUrlTakeover: handleBankAccountProofSignedUrl } =
    useGetSignedUrlTakeover({
      imageUrl: [toggleViewBankProofImageModal.imageUrl] ?? "",
    });

  function isUserFlagged(loanDetails: LoanDetailsType) {
    return loanDetails?.loan?.user?.additionalUserDetails?.some(
      (item) =>
        item?.flagged === FLAGTYPES.RED || item?.flagged === FLAGTYPES.YELLOW
    );
  }
  const contextValue = useMemo(() => {
    return {
      fetchBrlLoanDetailsLoading,
      defaultReasons,
      toggleFlagModal,
      toggleApproveLoanModal,
      toggleChangeBankAccountModal,
      toggleViewBankProofImageModal,
      toggleExcessAmountTransferModal,
      toggleRecordFundTransferGLModal,
      successModal,
      excessbuttonStatus,
      excessFundTransferDataByRazorPay,
      excessFundTransferDataByManual,
      handleBankAccountProofSignedUrl,
      setDefaultReasons,
      isUserFlagged,
      setFetchBrlLoanDetailsLoading,
      setToggleFlagModal,
      setToggleApproveLoanModal,
      setToggleChangeBankAccountModal,
      setToggleViewBankProofImageModal,
      setToggleExcessAmountTransferModal,
      setToggleRecordFundTransferGLModal,
      setSuccessModal,
      setExcessButtonStatus,
      setExcessTransferDataByRazorPay,
      setExcessTransferDataByManual,
    };
  }, [
    fetchBrlLoanDetailsLoading,
    defaultReasons,
    successModal,
    toggleFlagModal,
    toggleApproveLoanModal,
    toggleChangeBankAccountModal,
    toggleViewBankProofImageModal,
    toggleExcessAmountTransferModal,
    toggleRecordFundTransferGLModal,
    excessbuttonStatus,
    excessFundTransferDataByRazorPay,
    excessFundTransferDataByManual,
  ]);

  return (
    <LoanContext.Provider value={contextValue}>{children}</LoanContext.Provider>
  );
}

export { LoanContext, LoanProvider };
