import { useEffect, useState } from "react";
import useAxios from "../../lib/initAxios";
import { getValueFromLocalStorage } from "@utils/local-storage";

export default function useGetSignedUrlTakeover({
  imageUrl,
}: {
  imageUrl?: any[];
}) {
  const [url, setUrl] = useState<Array<any>>([]);
  const [{ data: response, loading }, execute] = useAxios(
    {
      baseURL: process.env.REACT_APP_REGISTER_API,
      url: "/api/v1/images",
      method: "POST",
      data: {
        urls: imageUrl,
      },
      headers: {
        "X-Renewal-Secret": process.env.REACT_APP_REGISTER_AUTHORIZATION || "",
        Authorization: `JWT ${getValueFromLocalStorage("token")}`,
      },
    },
    { manual: true }
  );

  useEffect(() => {
    if (response) {
      setUrl(response?.signedUrls);
    }
  }, [response]);

  return { url, loading, handleGetSignedUrlTakeover: execute };
}
