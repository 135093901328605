const PING_RESOURCE_URL = "/robots.txt";
const NETWORK_CHECK_POLLING_TIME = 10000;
const REQUEST_TIMEOUT = 60000;
export const FEDERAL_NOT_DONE_STATES = [
  "Pending",
  "Requested",
  "CST Approval Pending",
];
export const FEDERAL_USER_STATES = {
  CHECKING: "checking",
  IN_PROGRESS: "in-progress",
  VERIFIED: "verified",
  NOT_VERIFIED: "not-verified",
};

export const LOAN_FEDERAL_CHECK_TITLES = {
  GL_OPENING_COMPLETED: "GL Account Opening",
  GL_VALIDATION_COMPLETED: "GL Validation",
  GL_INSERTION_COMPLETED: "GL Account Insert",
  PLEDGE_CARD_UPDATED: "Pledge Card Submitted",
};

export const INITIAL_LOAN_FEDERAL_CHECK_STATE = (isError: any) => [
  {
    title: LOAN_FEDERAL_CHECK_TITLES.GL_OPENING_COMPLETED,
    value: isError ? false : undefined,
  },
  {
    title: LOAN_FEDERAL_CHECK_TITLES.GL_VALIDATION_COMPLETED,
    value: isError ? false : undefined,
  },
  {
    title: LOAN_FEDERAL_CHECK_TITLES.GL_INSERTION_COMPLETED,
    value: isError ? false : undefined,
  },
];

export const LOAN_GL_STATUS_TITLES = {
  LOAN_REQUEST: "Loan Request",
  PLEDGE_CARD: "Pledge Card",
  FUND_TRANSFER: "Fund Transfer",
  AGENT_VERIFICATION: "Agent Verification",
  LOAN_BRANCH: "Loan Branch",
  ACCOUNT: "Account Created",
  SECURITY: "Security",
  LOAN_DISBURSEMENT: "Loan Disbursement",
};

export const INITIAL_LOAN_GL_STATE = (isError: any) => [
  {
    title: LOAN_GL_STATUS_TITLES.LOAN_REQUEST,
    value: isError ? false : undefined,
  },
  {
    title: LOAN_GL_STATUS_TITLES.PLEDGE_CARD,
    value: isError ? false : undefined,
  },
  {
    title: LOAN_GL_STATUS_TITLES.FUND_TRANSFER,
    value: isError ? false : undefined,
  },
];

export const USER_FEDERAL_CHECK_TITLES = {
  EKYC_VERIFIED: "eKYC Verified",
  NAME_DOB_VALIDATED: "Name & DOB Validation",
  PAN_VALIDATED: "PAN Validated",
  UN_NAME_CHECKED: "UN Name Check",
  DEDUPE_CHECK: "Dedupe Checked",
  CUSTOMER_CREATED: "Customer Created",
  DEDUPE_CHECK_STATUS: "Customer Type",
  IS_MIN_KYC_CUSTOMER: "KYC Completed",
};

export const INITIAL_USER_FEDERAL_CHECK_STATE = (isError: any) => [
  {
    title: USER_FEDERAL_CHECK_TITLES.NAME_DOB_VALIDATED,
    value: isError ? false : undefined,
  },
  {
    title: USER_FEDERAL_CHECK_TITLES.DEDUPE_CHECK_STATUS,
    value: isError ? false : undefined,
  },
  {
    title: USER_FEDERAL_CHECK_TITLES.PAN_VALIDATED,
    value: isError ? false : undefined,
  },
  {
    title: USER_FEDERAL_CHECK_TITLES.UN_NAME_CHECKED,
    value: isError ? false : undefined,
  },
];

export const GL_PARTNER_CODE = {
  FEDERAL: "Federal",
  FINCARE: "Fincare",
  TPF: "TPF",
};

export const FEDERAL_CASES = {
  CASE_1: "New Customer & PAN Available",
  CASE_2: "New Customer & Form 60 Available",
  CASE_3: "Old Customer & min_kyc is false",
  CASE_4: "Old Customer & min_kyc is true",
};

export const LOAN_GL_STATUS_VERIFIED = {
  CST_APPROVED: "cst_approved",
  FUND_TRANSFER_DONE: "fund_transfer_done",
  PLEDGE_CARD_SUBMITTED: "pledge_card_submitted",
};

export const FEDERAL_CHECKS_NOT_RENDERED_BUT_VALIDATED: any[] = [];

export const mapPartnerCodeToPartnerName: any = {
  FEDERAL: "Federal",
  FINCARE: "Fincare",
  TPF: "TPF",
  SFL: "SFL",
};

export const LOAN_SUB_TYPE = {
  DC: "DC",
  TAKEOVER: "TAKEOVER",
  FRESH: "FRESH",
};

export const QC_STATUS = {
  PASSED: "QC_PASSED",
  FAILED: "QC_FAILED",
};

export const GOLD_SEAL_CAPTIONS = [
  "Seal ID",
  "Seal Cover Weight",
  "Sealed Gold",
];

const FLAGTYPES = {
  GREEN: "GREEN",
  YELLOW: "YELLOW",
  RED: "RED",
};

export {
  PING_RESOURCE_URL,
  NETWORK_CHECK_POLLING_TIME,
  REQUEST_TIMEOUT,
  FLAGTYPES,
};
