import React from "react";

// * utils
import {
  IPermissionsProviderProps,
  IPermissionsContext,
} from "@contexts/PermissionContext/interfaces";
import { deleteAllCookies, getValueFromCookie } from "@utils/cookies";
import {
  clearAllLocalStorage,
  getValueFromLocalStorage,
} from "@utils/local-storage";

// * initialize the context
const PermissionContext = React.createContext<IPermissionsContext>({
  permissions: [],
});

export function PermissionsProvider({ children }: IPermissionsProviderProps) {
  const [permissions, updatePermissions] = React.useState<String[]>([]);
  const value = React.useMemo(() => ({ permissions }), [permissions]);

  /**
   * * updates the permission of the user based on the token
   * * received from the login response and updates the user
   * * premissions on each login.
   */
  const updatePermissionsFromToken = () => {
    const token = getValueFromLocalStorage("token");
    if (token) {
      try {
        // const decodedToken: IToken = jwt_decode(token);
        const decodedToken = getValueFromLocalStorage("user");
        if (decodedToken) {
          const permissionArray: String[] = [];
          updatePermissions(permissionArray);
        }
      } catch (e) {
        deleteAllCookies();
        clearAllLocalStorage();
      }
    }
  };

  React.useEffect(() => {
    updatePermissionsFromToken();
  }, [getValueFromLocalStorage("token"), getValueFromCookie("user")]);

  return (
    <PermissionContext.Provider value={value}>
      {children}
    </PermissionContext.Provider>
  );
}

export function getUserPermissions() {
  const userPermissions = getValueFromLocalStorage("userPermissions");
  return JSON.parse(userPermissions);
}
